import { useEffect } from "react";
import Header from "../containers/About/Header";
import Applauds from "../containers/About/Applauds";
import Philosophy from "../containers/About/Philosophy";
import Purpose from "../containers/About/Purpose";
import Mission from "../containers/About/Mission";
import What from "../containers/About/What";
import Join from "../containers/Landing/Join";
import Begining from "../containers/About/Begining";

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <Philosophy />
            {/* <Applauds /> */}
            <Purpose />
            {/* <What /> */}
            {/* <Mission /> */}
            <Begining />
            <Join />
        </>
    );
}

export default AboutUs;
