import { Link } from "react-router-dom";

function LandingJoin() {
    return (
        <div className="h-[83vh] flex">
            <div className="flex flex-col text-center justify-center items-center gap-[28px] max-w-6xl mx-auto bg-vector bg-[center_bottom_10px] bg-no-repeat px-4">
                <p className="lg:text-[44px] text-[36px] leading-[57px] text-white font-bold mb-6">
                    Join Applaudable today
                </p>
                <p className="text-lg text-gray-300 mb-8 xl:px-40 px-4">
                    Share your journey, connect with others, and be part of a
                    world celebrating life's meaningful moments. Shape the
                    narrative, one Applause at a time.
                </p>
                <button className="bg-[#F72D55] text-white font-semibold py-3 px-6 rounded-full hover:bg-pink-600">
                    <Link to="/by-invitation"> Request an Invitation</Link>
                </button>
            </div>
        </div>
    );
}

export default LandingJoin;
