import React from "react";

type InputFieldProps = {
    label: string;
    name: string;
    value: string;
    error: string;
    placeholder: string;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    textarea?: boolean; // optional prop to determine if the input is a textarea
};

const InputField: React.FC<InputFieldProps> = ({
    label,
    name,
    value,
    error,
    placeholder,
    onChange,
    textarea = false,
}) => {
    return (
        <div className="flex flex-col gap-2 pt-4">
            <p className="text-white text-xs ml-2">{label}</p>
            {textarea ? (
                <textarea
                    className={`text-white rounded-lg p-4 px-4 border-none focus:outline ${
                        error ? "bg-red-300" : "bg-[#FFFFFF0D]"
                    } text-xs`}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={onChange}
                ></textarea>
            ) : (
                <input
                    className={`text-white rounded-lg p-4 px-4 border-none focus:outline ${
                        error ? "bg-red-300" : "bg-[#FFFFFF0D]"
                    } text-xs`}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={onChange}
                ></input>
            )}
            {error && <p className="text-red-500 text-xs">{error}</p>}
        </div>
    );
};

export default InputField;
