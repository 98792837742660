import React from "react";
import { FaUsers, FaApple } from "react-icons/fa";

interface CardProps {
    icon: React.ReactNode;
    title: string;
    content: string;
}

// type PillarProps = {
//     image: string;
//     title: string;
//     description: string;
// };

// const Pillar: React.FC<PillarProps> = ({ image, title, description }) => (
//     <div className="w-full mx-auto lg:mx-0 lg:w-1/3 bg-[#213837] lg:p-12 p-6 rounded-2xl">
//         <div className="flex gap-5">
//             <div className="flex-1">
//                 <img src={image} className="pb-12 pt-8" alt={title} />
//                 <h4 className="text-[28px] text-white font-semibold mb-2">
//                     {title}
//                 </h4>
//                 <p className="text-md text-white leading-8">{description}</p>
//             </div>
//         </div>
//     </div>
// );

const Card: React.FC<CardProps> = ({ icon, title, content }) => {
    return (
        <div className="flex flex-col items-center justify-center bg-[#152e2c] rounded-lg max-w-[500px] p-10">
            <div className="flex flex-col items-center justify-center w-48 h-48 bg-[#F72D55] rounded-full text-white mb-4">
                <div className="text-5xl mb-4">{icon}</div>
                <div className="text-center">{title}</div>
            </div>
            <div className="text-center">{content}</div>
        </div>
    );
};

export default Card;
