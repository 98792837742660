import device3 from "../../assets/images/device3.png";

function LandingAdimiration() {
    return (
        <div className="lg:py-32 px-4 py-8 flex flex-col">
            <div className="lg:bg-[#213837] rounded-3xl grid grid-cols-2 gap-12 lg:relative mt-[20px] lg:py-32">
                <div className="flex justify-center lg:col-span-1 col-span-2">
                    <img
                        src={device3}
                        alt="device2"
                        className="w-[400px] h-[820px] lg:absolute block left-[100px] top-[-100px] object-contain"
                    />
                </div>
                <div className="lg:px-10 px-0 flex flex-col lg:col-span-1 col-span-2 float-right lg:mt-0 mt-8">
                    <p className="lg:text-[44px] text-[32px] font-extrabold lg:leading-[57px] leading-[40px] text-left">
                        Admiration for Others
                    </p>
                    <p className="mt-10 lg:leading-8 leading-[25px]">
                        The third category focuses on recognizing the qualities
                        and contributions of others. Whether it's their
                        intellect, humor, creativity, or kindness, these
                        applauds are about shining a light on the positive
                        impact people have on us and our communities.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LandingAdimiration;
