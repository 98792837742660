import React from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import InputField from "../InputField"; // Adjust the path as necessary

type FormData = {
    name: string;
    email: string;
    message: string;
    phone_number: string;
};

type CareersFormProps = {
    formData: FormData;
    errors: FormData;
    message: string;
    resumeFile: FileWithPath | null;
    handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handleFileDrop: (acceptedFiles: FileWithPath[]) => void;
    handleSubmit: () => void;
};

const CareersForm: React.FC<CareersFormProps> = ({
    formData,
    errors,
    message,
    resumeFile,
    handleInputChange,
    handleFileDrop,
    handleSubmit,
}) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileDrop,
        accept: {
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
        },
    });

    return (
        <div className="bg-[#213837] lg:col-span-1 col-span-2 xl:px-16 px-4 rounded-3xl py-8 mx-4">
            <p className="font-extrabold xl:text-[24px] leading-[30px] text-left">
                Send Information
            </p>
            {Object.values(errors).some((error) => error) && (
                <div className="text-red-500 text-xs mb-2">
                    Please correct the errors before continuing.
                </div>
            )}
            {message !== "" && (
                <div className="text-green-200 text-xs mb-2">{message}</div>
            )}

            {/* Form Inputs */}
            <InputField
                label="Name"
                name="name"
                value={formData.name}
                error={errors.name}
                placeholder="Full Name"
                onChange={handleInputChange}
            />
            <InputField
                label="Email"
                name="email"
                value={formData.email}
                error={errors.email}
                placeholder="Email"
                onChange={handleInputChange}
            />
            <InputField
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number}
                error={errors.phone_number}
                placeholder="Phone Number"
                onChange={handleInputChange}
            />
            <InputField
                label="Message"
                name="message"
                value={formData.message}
                error={errors.message}
                placeholder="Type a Message..."
                onChange={handleInputChange}
                textarea
            />

            {/* Resume Dropzone */}
            <div {...getRootProps({ className: "dropzone" })}>
                <p className="text-white text-xs ml-2 mb-2 pt-4">Resume</p>
                <input {...getInputProps()} />
                <div className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-[#FFFFFF0D] dark:bg-gray-700 hover:bg-[#152E2C]">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                                Click to upload
                            </span>{" "}
                            or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">PDF or DOC</p>
                        {resumeFile && (
                            <ul className="mb-2 text-sm text-gray-500 mt-5">
                                <li>
                                    {resumeFile.path} - {resumeFile.size} bytes
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>

            <button
                className="rounded-full w-full font-medium bg-[#F72D55] hover:bg-[#e68195] py-2 px-6 leading-6 text-base my-4"
                onClick={handleSubmit}
            >
                Send
            </button>
        </div>
    );
};

export default CareersForm;
