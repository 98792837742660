import React from "react";
import CirclesCard from "../../assets/images/Circles Card.png";
import CirclesGroup from "../../assets/images/CircleGroup.png";
import CircleTextSection from "../../components/CircleTextSection";
import CircleImage from "../../components/CircleImage";

const LandingCircles: React.FC = () => {
    const heading = "Circles";
    const description = `
        On Applaudable, Circles are vibrant hubs for reflecting your wide-ranging interests—from
        gastronomy and globetrotting to uniting with others on similar personal and professional journeys.
        They're perfect for bringing together your communities, whether it's family, friends, fellow travelers,
        event-goers, or individuals on a parallel path. Circles are your go-to for posting significant milestones,
        discovering paths that inspire, and uncovering the greatest experiences of others.
    `;

    return (
        <div className="md:px-16 lg:py-36 py-8 grid grid-cols-2 gap-4 circles">
            <div className="lg:col-span-1 col-span-3 lg:hidden block">
                <CircleImage src={CirclesCard} alt="Circles Group" />
            </div>
            <div className="lg:col-span-1 col-span-3 lg:flex lg:items-center lg:justify-center">
                <div className="lg:max-w-[500px]">
                    <CircleTextSection
                        heading={heading}
                        description={description}
                    />
                </div>
            </div>
            <div className="lg:col-span-1 col-span-3 lg:block hidden">
                <CircleImage src={CirclesCard} alt="Circles Group" />
            </div>
        </div>
    );
};

export default LandingCircles;
