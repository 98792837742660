import { Scrollbar, Mousewheel, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import LandingEvery from "./Everyday";
import LandingPersonal from "./Personal";
import LandingAdimiration from "./Adimiration";
import device1 from "../../assets/images/device1.png";
import device2 from "../../assets/images/device2.png";
import device3 from "../../assets/images/device3.png";
import "./styles.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import "swiper/css/effect-coverflow";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/grid";

function Mission() {
    return (
        <>
            <div className="lg:px-16 lg:py-18 lg:flex hidden px-4 flex-col">
                <div className="lg:bg-[#213837] rounded-3xl grid grid-cols-3 gap-4">
                    <div className="lg:px-10 px-0 flex flex-col lg:col-span-3 col-span-3 justify-center items-center float-right lg:mt-0">
                        <section className="w-full pb-[2rem] bg-[#213837]">
                            <div className="xl:mx-auto max-w-6xl mx-[1.5rem]">
                                {/* Navigation buttons */}
                                <div className="swiper-button-prev after:text-white lg:after:bg-[#213837] after:py-3 after:pl-4 after:pr-4 after:rounded-[50%]"></div>
                                <div className="swiper-button-next after:text-white lg:after:bg-[#213837] after:py-3 after:pl-4 after:pr-4 after:rounded-[50%]"></div>

                                <Swiper
                                    modules={[
                                        // Scrollbar,
                                        // Mousewheel,
                                        Autoplay,
                                        Navigation,
                                    ]}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    centeredSlides={true}
                                    grabCursor={true}
                                    // scrollbar={{ draggable: true }}
                                    // mousewheel={{
                                    //     invert: false,
                                    // }}
                                    autoplay={{
                                        delay: 5000,
                                        stopOnLastSlide: false,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        0: {
                                            spaceBetween: 10,
                                            slidesPerView: 1,
                                        },
                                        600: {
                                            spaceBetween: 10,
                                            slidesPerView: 1,
                                        },
                                        820: {
                                            spaceBetween: 15,
                                            slidesPerView: 1,
                                        },
                                        1030: {
                                            spaceBetween: 15,
                                            slidesPerView: 1,
                                        },
                                        1100: {
                                            spaceBetween: 15,
                                            slidesPerView: 1,
                                        },
                                        1480: {
                                            spaceBetween: 30,
                                            slidesPerView: 1,
                                        },
                                    }}
                                    className="breakpoint"
                                >
                                    <SwiperSlide>
                                        <LandingEvery />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <LandingPersonal />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <LandingAdimiration />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div className="lg:px-16 lg:py-18 lg:hidden px-4 flex flex-col">
                <div className="lg:bg-[#213837] rounded-3xl grid grid-cols-3 gap-4">
                    <div className="lg:px-10 px-0 flex flex-col lg:col-span-3 col-span-3 justify-center items-center lg:mt-0">
                        <section className="w-full pt-[2rem] bg-[#213837] my-auto">
                            <div className="xl:mx-auto max-w-6xl mx-[1.5rem] relative">
                                {/* Navigation buttons */}
                                <div
                                    className="swiper-button-prev after:text-white after:bg-[#152e2c] after:py-2 after:pl-3 after:pr-3 after:rounded-[50%] after:absolute after:bottom-[-305px] after:left-24"
                                    style={{ fontSize: "12px" }}
                                ></div>
                                <div className="swiper-button-next after:text-white after:bg-[#152e2c] after:py-2 after:pl-3 after:pr-3 after:rounded-[50%] after:absolute after:bottom-[-305px] after:right-24"></div>

                                <Swiper
                                    modules={[
                                        // Scrollbar,
                                        // Mousewheel,
                                        // Autoplay,
                                        Navigation,
                                    ]}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    centeredSlides={true}
                                    grabCursor={true}
                                    // scrollbar={{ draggable: true }}
                                    // mousewheel={{
                                    //     invert: false,
                                    // }}
                                    autoplay={{
                                        delay: 5000,
                                        stopOnLastSlide: false,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        0: {
                                            spaceBetween: 10,
                                            slidesPerView: 1,
                                        },
                                        600: {
                                            spaceBetween: 10,
                                            slidesPerView: 1,
                                        },
                                        820: {
                                            spaceBetween: 15,
                                            slidesPerView: 1,
                                        },
                                        1030: {
                                            spaceBetween: 15,
                                            slidesPerView: 1,
                                        },
                                        1100: {
                                            spaceBetween: 15,
                                            slidesPerView: 1,
                                        },
                                        1480: {
                                            spaceBetween: 30,
                                            slidesPerView: 1,
                                        },
                                    }}
                                    className="breakpoint"
                                >
                                    <SwiperSlide className="my-auto">
                                        <div className="lg:px-10 px-0 flex flex-col float-right lg:mt-0 mt-8 h-full items-center">
                                            <div>
                                                <p className="lg:text-[44px] text-[32px] font-extrabold lg:leading-[57px] leading-[40px] text-center">
                                                    Everyday Joys and
                                                    Discoveries
                                                </p>
                                                <p className="mt-10 lg:leading-8 leading-[25px] text-center">
                                                    These applauds capture the
                                                    essence of daily life – the
                                                    places we visit, the
                                                    activities we engage in, the
                                                    media we consume, and the
                                                    joy of companionship.
                                                    They're about celebrating
                                                    the present and finding
                                                    beauty in the ordinary.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="">
                                            <img
                                                src={device1}
                                                alt="device2"
                                                className="w-[400px] h-[520px] object-contain"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="my-auto">
                                        <div className="lg:px-10 px-0 flex flex-col float-right lg:mt-0 mt-8 items-center justify-center">
                                            <p className="lg:text-[44px] text-[32px] font-extrabold lg:leading-[57px] leading-[40px] text-center">
                                                Personal Milestones and
                                                Inspirations
                                            </p>
                                            <p className="mt-10 lg:leading-8 leading-[25px] text-center">
                                                At a deeper level, applauds in
                                                this category are reflections on
                                                personal growth and
                                                achievements. They encompass
                                                aspirations, inspirations,
                                                perseverance through challenges,
                                                and the significant impact of
                                                our environment and
                                                relationships. These applauds
                                                are about acknowledging the
                                                journey and the individuals who
                                                fuel our progress.
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="">
                                            <img
                                                src={device2}
                                                alt="device2"
                                                className="w-[400px] h-[520px] object-contain"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="my-auto">
                                        <div className="flex justify-center item-center h-full">
                                            <div>
                                                <p className="lg:text-[44px] text-[32px] font-extrabold lg:leading-[57px] leading-[40px] text-center">
                                                    Admiration for Others
                                                </p>
                                                <p className="mt-10 lg:leading-8 leading-[25px] text-center">
                                                    The third category focuses
                                                    on recognizing the qualities
                                                    and contributions of others.
                                                    Whether it's their
                                                    intellect, humor,
                                                    creativity, or kindness,
                                                    these applauds are about
                                                    shining a light on the
                                                    positive impact people have
                                                    on us and our communities.
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="">
                                            <img
                                                src={device3}
                                                alt="device2"
                                                className="w-[400px] h-[520px] object-contain"
                                            />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mission;
