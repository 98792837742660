import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/Vector.png";
import close from "../../assets/icons/delete.png";

const ConfirmInvitation: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-[#152E2C] p-4">
            <div className="bg-[#213937] rounded-lg shadow-xl w-full max-w-xs w-[446px] h-[467px]">
                <Link to="/">
                    <img src={close} alt="delete" className="float-right p-2" />
                </Link>
                <div className="p-6 text-center">
                    <div className="mb-6">
                        <img
                            src={logo}
                            alt="Applaudable Logo"
                            className="mx-auto h-[40px] w-12"
                        />
                        <div className="font-hel text-xs">Applaudable</div>
                    </div>
                    <h2 className="font-hel font-bold text-xl mt-16">
                        Hang tight!
                    </h2>
                    <p className=" mb-6 font-bold text-xl">
                        We'll reach out with an invitation asap.
                    </p>
                    <p className="text-xl font-bold">
                        Meanwhile, if you have a friend on Applaudable, just ask
                        them.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ConfirmInvitation;
