import logo from "../../assets/images/Logo.png";

function More() {
    return (
        <div className="flex px-4 lg:py-18 py-16 personal justify-center">
            <div className="max-w-[1700px] text-start">
                <p className="lg:text-5xl text-[32px] font-extrabold leading-11 mb-16 text-center">
                    More Info
                </p>
                <div className="grid grid-cols-3 gap-4 max-w-[1500px] w-full">
                    <div className="lg:col-span-1 col-span-3 flex flex-col w-full mx-auto lg:mx-0 bg-[#213837] lg:p-12 p-6 rounded-2xl justify-between">
                        <div>
                            <h4 className="text-[28px] text-white font-semibold mb-2">
                                Press Kit
                            </h4>
                            <p className="text-md text-white leading-8 py-4">
                                Share your journey, connect with others, and be
                                part of a world celebrating life's meaningful
                                moments.
                            </p>
                        </div>
                        <a
                            href="/path/to/press-kit.zip" // Update with the actual path to your press kit file
                            download
                            style={{ height: "50px" }}
                            className="text-white bg-[#FFFFFF0D] hover:bg-[#ffffff1f] focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 inline-flex items-center max-w-[108px]"
                        >
                            Download
                            <svg
                                className="fill-current w-4 h-4 ml-2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                            </svg>
                        </a>
                    </div>
                    <div className="lg:col-span-1 col-span-3 flex flex-col w-full mx-auto lg:mx-0 bg-[#213837] lg:p-12 p-6 rounded-2xl justify-between">
                        <div>
                            <h4 className="text-[28px] text-white font-semibold mb-2">
                                Press Releases
                            </h4>
                            <p className="text-md text-white leading-8 py-4">
                                Stay up-to-date with our latest news and
                                announcements.
                            </p>
                        </div>
                        <a
                            href="/press-releases" // Assuming your press releases page is at this route
                            className="text-white bg-[#F72D55] hover:bg-pink-600 focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 inline-flex items-center max-w-[120px]"
                            style={{ height: "50px" }}
                        >
                            View All
                            <svg
                                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 10"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="lg:col-span-1 col-span-3 flex flex-col w-full mx-auto lg:mx-0 bg-[#213837] lg:p-12 p-6 rounded-2xl justify-between">
                        <div>
                            <h4 className="text-[28px] text-white font-semibold mb-8">
                                Brands Assets
                            </h4>
                            <img
                                src={logo}
                                alt=""
                                className="w-[100px] h-[100px] lg:my-4 mb-10"
                            />
                        </div>
                        <a
                            href="/path/to/brand-assets.zip" // Update with the actual path to your brand assets file
                            download
                            className="text-white bg-[#FFFFFF0D] hover:bg-[#ffffff1f] focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 inline-flex items-center max-w-[108px]"
                            style={{ height: "50px" }}
                        >
                            Download
                            <svg
                                className="fill-current w-4 h-4 ml-2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default More;
