import React, { useState, useEffect, useRef } from "react";
import { useSprings, animated, to as interpolate } from "@react-spring/web";
import image01 from "../../assets/images/exp/landing1.png";
import image02 from "../../assets/images/exp/landing2.png";
import image03 from "../../assets/images/exp/landing3.png";
import image04 from "../../assets/images/exp/landing4.png";
import image05 from "../../assets/images/exp/landing5.png";
import image06 from "../../assets/images/exp/landing6.png";
import image07 from "../../assets/images/exp/landing7.png";
import image08 from "../../assets/images/exp/landing8.png";
import image09 from "../../assets/images/exp/landing9.png";
import image10 from "../../assets/images/exp/landing10.png";
import styles from "./styles.module.css";

const cards = [
    image01,
    image02,
    image03,
    image04,
    image05,
    image06,
    image07,
    image08,
    image09,
    image10,
];

// Helper function to generate the 'entering' animation properties
const to = (i: number) => ({
    x: 120 * Math.random() - 60,
    y: 120 * Math.random() - 60,
    scale: 1,
    rot: 0,
    opacity: 1,
    delay: i * 100,
});

// Helper function to generate the 'exiting' or 'from' animation properties
const from = () => ({
    x: 0,
    y: 0,
    rot: 0,
    scale: 1.2,
    opacity: 0,
});

// Helper to transform rotation and scale into a CSS transform
const trans = (r: number, s: number) =>
    `perspective(1500px) rotateX(30deg) rotateY(${
        r / 100
    }deg) rotateZ(${r}deg) scale(${s})`;

// Function to shuffle an array
const shuffleArray = <T,>(array: T[]): T[] => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

function Deck() {
    const [gone] = useState(() => new Set<number>());
    const [visible, setVisible] = useState<boolean[]>(
        new Array(cards.length).fill(false)
    );
    const [shuffledCards, setShuffledCards] = useState(() =>
        shuffleArray([...cards])
    );

    // Reference to track each card element
    const cardRefs = useRef<(HTMLDivElement | null)[]>(
        new Array(cards.length).fill(null)
    );

    // Springs to control the animation
    const [props, api] = useSprings(cards.length, (i) => ({
        ...from(),
        config: { mass: 0, tension: 20000, friction: 100 },
    }));

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const index = cardRefs.current.indexOf(
                        entry.target as HTMLDivElement
                    );
                    if (index !== -1) {
                        setVisible((v) => {
                            const newVisibilities = [...v];
                            newVisibilities[index] = entry.isIntersecting;
                            return newVisibilities;
                        });
                        if (entry.isIntersecting) {
                            api.start((i) => {
                                if (i === index) return to(i);
                                return;
                            });
                        } else {
                            api.start((i) => {
                                if (i === index) return from();
                                return;
                            });
                        }
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        cardRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            cardRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [api]);

    useEffect(() => {
        setShuffledCards(shuffleArray([...cards]));
    }, []);

    useEffect(() => {
        if (visible.includes(true)) {
            setShuffledCards(shuffleArray([...cards]));
        }
    }, [visible]);

    return (
        <div>
            {props.map(({ x, y, rot, scale, opacity }, i) => (
                <animated.div
                    className={styles.deck}
                    key={i}
                    style={{ x, y, opacity }}
                    ref={(el) => (cardRefs.current[i] = el)}
                >
                    <animated.div
                        style={{
                            transform: interpolate([rot, scale], trans),
                            backgroundImage: `url(${shuffledCards[i]})`,
                        }}
                    />
                </animated.div>
            ))}
        </div>
    );
}

export default function Animation() {
    return (
        <div className={styles.container}>
            <Deck />
        </div>
    );
}
