import React from "react";

const MobileAccess: React.FC = () => {
    return (
        <div className="lg:px-16 px-4 lg:pt-48 pt-24 flex justify-center gap-8 lg:pb-80 pb-40">
            <div className="lg:px-10 lg:py-10 px-0 py-0 max-w-[1100px]">
                <p className="font-extrabold lg:text-[44px] text-[24px] lg:leading-[66px] leading-[40px] text-center">
                    Ooops! Mobile-Only Access
                </p>
                <p className="mt-10 lg:leading-8 leading-[25px] text-center max-w-[1100px]">
                    The link you opened is only accessible on a mobile device.
                    Please try opening this link on your smartphone or tablet.
                </p>
            </div>
        </div>
    );
};

export default MobileAccess;
