import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import LandingMeaning from "../containers/Landing/Meaning";
import LandingExp from "../containers/Landing/Exp";
import LandingHow from "../containers/Landing/How";
import LandingPersonal from "../containers/Landing/Personal";
import LandingEveryday from "../containers/Landing/Everyday";
import LandingAdimiration from "../containers/Landing/Adimiration";
import LandingImpact from "../containers/Landing/Impact";
import LandingCircles from "../containers/Landing/Circles";
import LandingJourney from "../containers/Landing/Journey";
import LandingJoin from "../containers/Landing/Join";
import Mission from "../containers/Landing/Swiper";

gsap.registerPlugin(ScrollTrigger);

const Landing: React.FC = () => {
    window.scrollTo(0, 0);
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);
    const ref = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        ref.current.forEach((el: HTMLDivElement) => {
            gsap.fromTo(
                el,
                { autoAlpha: 0, y: 100 },
                {
                    duration: 0.5,
                    autoAlpha: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom-=100",
                        end: "bottom top",
                        toggleActions: "play none none reverse",
                        onEnter: () =>
                            gsap.fromTo(
                                el,
                                { y: 200, autoAlpha: 0 },
                                { y: 0, autoAlpha: 1, duration: 0.5 }
                            ),
                        onLeaveBack: () =>
                            gsap.fromTo(
                                el,
                                { y: 0, autoAlpha: 1 },
                                { y: 200, autoAlpha: 0, duration: 0.5 }
                            ),
                    },
                }
            );
        });
    }, []);

    const addtoRefs = (el: HTMLDivElement | null) => {
        if (el && !ref.current.includes(el)) {
            ref.current.push(el);
        }
    };

    return (
        <>
            <div ref={addtoRefs} className="box">
                <LandingMeaning />
            </div>
            <div ref={addtoRefs} className="box">
                <LandingExp />
            </div>
            <div className="bg-[#152E2C] flex justify-center">
                <div className="App max-w-[1400px]">
                    <div ref={addtoRefs} className="box">
                        <LandingHow />
                    </div>
                    <div ref={addtoRefs} className="box my-10">
                        <Mission />
                    </div>
                    {/* <div ref={addtoRefs} className="box lg:hidden block">
                        <LandingEveryday />
                    </div>
                    <div ref={addtoRefs} className="box lg:hidden block">
                        <LandingPersonal />
                    </div>
                    <div ref={addtoRefs} className="box lg:hidden block">
                        <LandingAdimiration />
                    </div> */}
                    <div ref={addtoRefs} className="box">
                        <LandingCircles />
                    </div>
                    <div ref={addtoRefs} className="box">
                        <LandingImpact />
                    </div>
                    <div ref={addtoRefs} className="box">
                        <LandingJourney />
                    </div>
                </div>
            </div>
            <div ref={addtoRefs} className="box">
                <LandingJoin />
            </div>
        </>
    );
};

export default Landing;
