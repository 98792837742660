import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../config/config";
import FormComponent from "../../components/ContactForm"; // Import the FormComponent component
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

type FormData = {
    name: string;
    email: string;
    message: string;
};

const ContactForm: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        message: "",
    });
    const [errors, setErrors] = useState<FormData>({
        name: "",
        email: "",
        message: "",
    });
    const [message, setMessage] = useState<string>("");

    const validateEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // Reset errors for specific field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = async () => {
        let currentErrors = {
            name: "",
            email: "",
            message: "",
        };

        // Validate empty fields
        if (!formData.name) currentErrors.name = "Name is required";
        if (!formData.message) currentErrors.message = "Message is required";
        if (!formData.email) currentErrors.email = "Email is required";

        // Validate formats
        if (formData.email && !validateEmail(formData.email)) {
            currentErrors.email = "Invalid email format";
        }

        setErrors(currentErrors);

        // Check if there are any errors
        const hasErrors = Object.values(currentErrors).some((error) => error);
        if (hasErrors) return;

        try {
            const response = await axios.post(
                `${config.API.SERVER_API}/auth/contact/`,
                {
                    ...formData,
                }
            );
            setMessage(response.data.message);
        } catch (error: any) {
            console.error("There was an error sending the request", error);
        }
    };

    return (
        <div className="flex justify-center bg-[#152E2C] lg:py-36 py-24">
            <div className="grid grid-cols-2 gap-12 lg:px-24 px-0 max-w-[1700px]">
                <div className="lg:col-span-1 col-span-2 mx-2">
                    <p className="text-[44px] font-extrabold leading-[57px]">
                        Contact Us
                    </p>
                    <p className="mt-10 text-xl leading-8">
                        If you have any questions about the platform, contact us
                        via this form. Press inquiries can be submitted here.
                    </p>
                </div>
                <FormComponent
                    formData={formData}
                    errors={errors}
                    message={message}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default ContactForm;
