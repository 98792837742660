function LandingHow() {
    return (
        <div className="lg:px-16 px-4 lg:pt-36 pt-24 flex justify-center gap-8">
            <div className="lg:px-10 lg:py-10 px-0 py-0 max-w-[1100px]">
                <p className="font-extrabold lg:text-[44px] text-[32px] lg:leading-[66px] leading-[40px] text-center">
                    How Applaudable is Used
                </p>
                <p className="mt-10 lg:leading-8 leading-[25px] text-center max-w-[1100px]">
                    At Applaudable, we're not just creating a social media
                    platform; we're redefining the essence of recognition. Our
                    platform is designed around the concept of "applauds" – a
                    transformative way for individuals to celebrate, share, and
                    inspire through everyday and profound moments alike.
                    Applauds serve as the core interaction within our community,
                    categorized into three distinct yet interconnected
                    experiences:
                </p>
            </div>
        </div>
    );
}

export default LandingHow;
