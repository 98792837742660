import React, { useState, useEffect } from "react";
import Contact from "../containers/Press/PressForm";
import Fact from "../containers/Press/Fact";
import More from "../containers/Press/More";

const Press: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    window.scrollTo(0, 0);
    return (
        <div className="flex justify-center">
            <div className="max-w-[1500px]">
                <Contact />
                <Fact />
                <More />
            </div>
        </div>
    );
};

export default Press;
