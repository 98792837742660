import React, { MouseEventHandler } from "react";
import { config } from "../../config/config";

function LandingMeaning() {
    function scrollToSection(sectionId: string): MouseEventHandler {
        return () => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        };
    }

    return (
        <div className="grid grid-cols-10 justify-center">
            <div className="lg:col-span-5 col-span-10 text-start xl:px-24 lg:px-18 px-4 lg:py-3 pt-16 flex justify-center items-center">
                <div className="max-w-[550px]">
                    <div className="lg:text-[44px] text-[32px] lg:leading-[49px] leading-[40px] font-extrabold text-left">
                        Awareness of what has meaning is what gives it meaning.
                    </div>
                    <div className="lg:text-[20px] text-[16px] lg:leading-8 leading-[25px] font-extrabold mt-5 text-left">
                        Applaudable is a social platform designed to capture and
                        share the experiences you love.
                    </div>
                    <div className="col-span-10 py-10">
                        <button
                            className="rounded-full bg-[#F72D55] hover:bg-[#e68195] py-2 px-6"
                            onClick={scrollToSection("get-app-web")}
                        >
                            Get the App
                        </button>
                    </div>
                </div>
            </div>
            <div className="lg:col-span-5 col-span-10">
                <video
                    src={config.SOURCE.VIDEO_URL}
                    autoPlay
                    muted
                    loop
                    className="w-full lg:h-[calc(100vh-66px)] lg:object-cover"
                ></video>
            </div>
        </div>
    );
}

export default LandingMeaning;
