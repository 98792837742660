const Fact: React.FC = () => {
    return (
        <div className="container mx-auto md:px-4">
            <section className="mb-8 text-center lg:mx-0 mx-4">
                <div className="grid grid-cols-4 bg-[#213837] rounded-lg lg:py-16 py-8 text-left lg:px-24 px-8">
                    <div className="lg:col-span-1 col-span-2">
                        <p className="lg:mb-4 mb-0 lg:text-lg text-[14px] font-medium">
                            Founded
                        </p>
                        <p className="lg:mb-4 mb-10 lg:text-4xl text-[26px] font-bold text-primary dark:text-primary-400">
                            2016
                        </p>
                    </div>
                    <div className="lg:col-span-1 col-span-2">
                        <p className="lg:mb-4 mb-0 lg:text-lg font-medium">
                            HEADQUARTERED
                        </p>
                        <p className="lg:mb-4 mb-10 lg:text-4xl text-[26px] font-bold text-primary dark:text-primary-400">
                            Delaware
                        </p>
                    </div>
                    <div className="lg:col-span-1 col-span-2">
                        <p className="lg:mb-4 mb-0 lg:text-lg text-[14px] font-medium">
                            INVESTORS
                        </p>
                        <p className="mb-4 lg:text-4xl text-[26px] font-bold text-primary dark:text-primary-400">
                            5000 +
                        </p>
                    </div>
                    <div className="lg:col-span-1 col-span-2">
                        <p className="lg:mb-4 mb-0 lg:text-lg text-[14px] font-medium">
                            LOCATED
                        </p>
                        <p className="mb-4 lg:text-4xl text-[26px] font-bold text-primary dark:text-primary-400">
                            13 Countries
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Fact;
