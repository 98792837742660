import impact from "../../assets/images/impact.png";

function LandingImpact() {
    return (
        <div className="md:px-16 lg:py-40 py-16 lg:pt-0 grid grid-cols-2">
            <div className="lg:col-span-1 col-span-2 flex justify-center items-center">
                <img src={impact} alt="impact" className="rounded-[20px]" />
            </div>
            <div className="lg:col-span-1 col-span-2 flex flex-col justify-center lg:px-16 py-10 px-4">
                <p className="lg:text-[44px] text-[36px] font-bold lg:leading-11 leading-[57px]">
                    See Your Impact
                </p>
                <p className="my-10 lg:leading-8 leading-[25px]">
                    Your stories resonate far beyond their initial sharing,
                    inspiring others to seek similar joys and find personal
                    significance in them. Witness how your shared experiences
                    cultivate a cycle of inspiration, where simple delights and
                    profound insights perpetuate a community engaged in
                    meaningful discovery and connection.
                </p>
                <p className="font-medium text-[16px] lg:leading-8 leading-[25px]">
                    Sharing experiences improves the experience...but more than
                    that, it also creates that same possibility for others.
                </p>
            </div>
        </div>
    );
}

export default LandingImpact;
