import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../assets/icons/Vector.png";
import close from "../assets/icons/delete.png";
import { config } from "../../config/config";
import FormComponent from "../../components/ContactForm"; // Import the FormComponent component
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

type FormData = {
    name: string;
    email: string;
    message: string;
};

const Press: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        message: "",
    });
    const [errors, setErrors] = useState<FormData>({
        name: "",
        email: "",
        message: "",
    });
    const [message, setMessage] = useState<string>("");

    const validateEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // Reset errors for specific field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleSubmit = async () => {
        let currentErrors = {
            name: "",
            email: "",
            message: "",
        };

        // Validate empty fields
        if (!formData.name) currentErrors.name = "Name is required";
        if (!formData.message) currentErrors.message = "Message is required";
        if (!formData.email) currentErrors.email = "Email is required";

        // Validate formats
        if (formData.email && !validateEmail(formData.email)) {
            currentErrors.email = "Invalid email format";
        }

        setErrors(currentErrors);

        // Check if there are any errors
        const hasErrors = Object.values(currentErrors).some((error) => error);
        if (hasErrors) return;

        try {
            const response = await axios.post(
                `${config.API.SERVER_API}/auth/press/`,
                {
                    ...formData,
                }
            );
            setMessage(response.data.message);
        } catch (error: any) {
            console.error("There was an error sending the request", error);
        }
    };

    return (
        <div className="flex justify-center bg-[#152E2C] lg:py-18 py-12">
            <div className="grid grid-cols-2 gap-12 mb-12 w-full">
                <div className="lg:col-span-1 col-span-2 mx-4 max-w-[550px]">
                    <p className="lg:text-[44px] text-[32px] font-extrabold leading-11">
                        Applaudable Press
                    </p>
                    <p className="mt-10 text-xl leading-8 mb-8">
                        At Applaudable, we believe in the power of storytelling
                        to connect and inspire. Our press team is dedicated to
                        sharing the unique and impactful stories that define our
                        community. Through collaboration with journalists
                        worldwide, we aim to highlight the moments that matter
                        most. <br />
                        <br />
                        For media inquiries, please reach out to us at
                        press@applaudable.com. For all other questions or
                        feedback, visit our
                        <Link className="font-medium hover:underline" to="">
                            contact page
                        </Link>
                    </p>
                </div>
                <FormComponent
                    formData={formData}
                    errors={errors}
                    message={message}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                />
            </div>
        </div>
    );
};

export default Press;
