import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer mt-10">
      <Link
        to="/terms-privacy"
        className="text-white terms-link"
        onClick={scrollToTop}
      >
        Terms & Privacy
      </Link>
      <span className="copyright">
        WeLivv, Inc. © 2024. All Rights Reserved.
      </span>
    </footer>
  );
};

export default Footer;
