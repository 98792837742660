import React from "react";
import InputField from "../InputField"; // Assuming InputField is already created

type FormComponentProps = {
    formData: {
        name: string;
        email: string;
        message: string;
    };
    errors: {
        name: string;
        email: string;
        message: string;
    };
    message: string;
    handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    handleSubmit: () => Promise<void>;
};

const FormComponent: React.FC<FormComponentProps> = ({
    formData,
    errors,
    message,
    handleInputChange,
    handleSubmit,
}) => {
    return (
        <div className="bg-[#213837] lg:col-span-1 col-span-2 lg:px-16 px-4 rounded-3xl py-8 mx-2">
            <p className="font-extrabold xl:text-[24px] leading-[30px] text-left">
                Send Information
            </p>
            {Object.values(errors).some((error) => error) && (
                <div className="text-red-500 text-xs mb-2">
                    Please correct the errors before continuing.
                </div>
            )}
            {message && (
                <div className="text-green-200 text-xs mb-2">{message}</div>
            )}
            <InputField
                label="Name"
                name="name"
                value={formData.name}
                error={errors.name}
                placeholder="Full Name"
                onChange={handleInputChange}
            />
            <InputField
                label="Email"
                name="email"
                value={formData.email}
                error={errors.email}
                placeholder="Email"
                onChange={handleInputChange}
            />
            <InputField
                label="Message"
                name="message"
                value={formData.message}
                error={errors.message}
                placeholder="Type a Message..."
                onChange={handleInputChange}
                textarea
            />
            <button
                className="rounded-full font-medium bg-[#F72D55] hover:bg-[#e68195] py-2 px-6 leading-6 text-base my-4 w-full"
                onClick={handleSubmit}
            >
                Continue
            </button>
        </div>
    );
};

export default FormComponent;
