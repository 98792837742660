import React, { MouseEventHandler } from "react";
import home from "../../assets/images/home.jpg";

const Header: React.FC = () => {
    function scrollToSection(sectionId: string): MouseEventHandler {
        return () => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        };
    }

    return (
        <div className="flex justify-center">
            <div className="grid grid-cols-10 w-full">
                <div className="xl:col-span-5 lg:col-span-6 col-span-10 text-center xl:px-16 lg:-4 py-6 pt-16 flex flex-col justify-center items-center mx-4 max-w-[800px]">
                    <div className="lg:text-[44px] text-[22px] lg:leading-[49px] leading-[40px] font-extrabold text-left">
                        Applaudable is a new social platform that facilitates
                        the recognition of our positive and meaningful
                        experiences, improving our appreciation of them and
                        maximizing their impact on others.
                    </div>
                </div>
                <div className="xl:col-span-5 lg:col-span-4 col-span-10">
                    <img
                        src={home}
                        alt="journey"
                        className="w-full lg:h-[calc(100vh-65px)] lg:object-cover"
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
