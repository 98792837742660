import React from "react";
import Section from "../../components/PhiloSection";
import Profile from "../../components/Profile";

const Philosophy: React.FC = () => {
    return (
        // <div className="flex justify-center lg:px-16 px-4">
        //     <div className="grid grid-cols-3 gap-5 max-w-[1500px]">
        //         <Section
        //             title="Our Beginning"
        //             additionalClasses="lg:col-span-2 col-span-3"
        //         >
        //             <p className="text-base leading-8 sm:pt-20 pt-8 font-bold">
        //                 It started decades ago…
        //             </p>
        //             <p className="text-base leading-8 pt-8">
        //                 I had a rather unusual curiosity about people from a
        //                 young age. I remember wondering who strangers were,
        //                 where they’d been, what they did, etc. Over time, this
        //                 curiosity matured a bit. I’d wonder how people could end
        //                 up in such different places and with such vastness in
        //                 their quality of life from others with similar starting
        //                 points. I became fascinated with what affected this and
        //                 how much we were even aware of, as it was happening. How
        //                 much was deliberate?
        //             </p>
        //             <p className="text-base leading-8 pt-8">
        //                 I learned a lot from developing the original vision of
        //                 the company, which focused on understanding the impact
        //                 our physical surroundings have on how we feel and the
        //                 standard to which environments inform how we live our
        //                 lives. We focused on curating photos of great interiors
        //                 and using them to inspire and inform improvements in the
        //                 spaces of others. More than just developing a
        //                 perspective on the significance of environments, often
        //                 in ways not recognized consciously, we also began to
        //                 understand why people shared personal experiences (which
        //                 initially were limited to photos of their homes)
        //                 publicly and the impact of those experiences on others.
        //                 The idea that we could share something from our own
        //                 lives in a way that could improve somebody else’s was
        //                 exciting, and obviously, its potential wasn’t limited to
        //                 photos of people’s homes. This is how Applaudable was
        //                 conceived.
        //             </p>
        //             <Profile />
        //         </Section>

        //         <div className="lg:col-span-1 col-span-3">
        //             <Section title="Our Philosophy" additionalClasses="h-[52%]">
        //                 <p className="text-base leading-8 pt-8">
        //                     Our lives are a collection of experiences. In turn,
        //                     these experiences influence and shape our lives. The
        //                     net aggregate of our experiences and how they’ve
        //                     affected us make up who we are in this world. Who we
        //                     are and the experiences we are having currently,
        //                     dictate how we feel and behave. We believe we can be
        //                     much more conscious and deliberate.
        //                 </p>
        //             </Section>

        //             <Section
        //                 title="A New Era"
        //                 additionalClasses="mt-[1.25rem] h-[45.5%]"
        //             >
        //                 <p className="text-left leading-8 pt-8">
        //                     We’ve reimagined social media. Our platform is built
        //                     on the belief that social interactions should be
        //                     genuine, enriching, and celebratory. With Applauds,
        //                     we offer a new way to connect, share, and build a
        //                     community that values depth, authenticity, and
        //                     mutual respect.
        //                 </p>
        //             </Section>
        //         </div>
        //     </div>
        // </div>
        <div className="lg:px-16 px-4 lg:pt-36 pt-24 flex justify-center gap-8">
            <div className="lg:px-10 lg:py-10 px-0 py-0 max-w-[1100px]">
                <p className="font-extrabold lg:text-[44px] text-[32px] lg:leading-[66px] leading-[40px] text-center">
                    Our Philosophy
                </p>
                <p className="mt-10 lg:leading-8 leading-[25px] text-center max-w-[1100px]">
                    Our lives are a collection of experiences. In turn, these
                    experiences influence and shape our lives. The net aggregate
                    of our experiences and how they’ve affected us make up who
                    we are in this world. Who we are and the experiences we are
                    having currently, dictate how we feel and behave. We believe
                    we can be much more conscious and deliberate.
                </p>
            </div>
        </div>
    );
};

export default Philosophy;
