import React from "react";

const TermsContainer: React.FC = () => {
    return (
        <div>
            <div className="terms-privacy-page">
                <p className="important-notice">Terms & Privacy</p>
                <p>
                    Welcome to Applaudable These Terms of Use (or "Terms")
                    govern your use of Applaudable, except where we expressly
                    state that separate terms (and not these) apply, and provide
                    information about the Applaudable Service (the "Service"),
                    outlined below. When you create an Applaudable account or
                    use Applaudable, you agree to these terms.
                    <br />
                    <br />
                    The Applaudable Service is one of the WeLivv Products,
                    provided to you by WeLivv, Inc. These Terms of Use therefore
                    constitute an agreement between you and WeLivv, Inc.
                    <br />
                    <br />
                    ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND
                    US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND
                    YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
                    LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN SOME
                    EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION BELOW.
                </p>

                <p className="important-notice">The Applaudable Service</p>
                <p>
                    We agree to provide you with the Applaudable Service. The
                    Service includes all of the Applaudable products, features,
                    applications, services, technologies, and software that we
                    provide to advance Applaudable's mission: To bring you
                    closer to the people and things you love. The Service is
                    made up of the following aspects:
                </p>

                <p className="important-notice">
                    Offering personalized opportunities to create, connect,
                    communicate, discover, and share
                </p>
                <p>
                    People are different. We want to strengthen your
                    relationships through shared experiences you actually care
                    about. So we build systems that try to understand who and
                    what you and others care about, and use that information to
                    help you create, find, join, and share in experiences that
                    matter to you. Part of that is highlighting content,
                    features, offers, and accounts you might be interested in,
                    and offering ways for you to experience Applaudable, based
                    on things you and others do on and off Applaudable.
                </p>

                <p className="important-notice">
                    Fostering a positive, inclusive, and safe environment
                </p>
                <p>
                    We develop and use tools and offer resources to our
                    community members that help to make their experiences
                    positive and inclusive, including when we think they might
                    need help. We also have teams and systems that work to
                    combat abuse and violations of our Terms and policies, as
                    well as harmful and deceptive behavior. We use all the
                    information we have-including your information-to try to
                    keep our platform secure. We also may share information
                    about misuse or harmful content with other WeLivv Companies
                    or law enforcement. Learn more in the Data Policy.
                </p>

                <p className="important-notice">
                    Developing and using technologies that help us consistently
                    serve our growing community
                </p>
                <p>
                    Organizing and analyzing information for our growing
                    community is central to our Service. A big part of our
                    Service is creating and using cutting-edge technologies that
                    help us personalize, protect, and improve our Service on an
                    incredibly large scale for a broad global community.
                    Technologies like artificial intelligence and machine
                    learning give us the power to apply complex processes across
                    our Service. Automated technologies also help us ensure the
                    functionality and integrity of our Service.
                </p>

                <p className="important-notice">
                    Providing consistent and seamless experiences across other
                    WeLivv Company Products
                </p>
                <p>
                    Applaudable is part of the WeLivv Companies, which share
                    technology, systems, insights, and information-including the
                    information we have about you (learn more in the Data
                    Policy) in order to provide services that are better, safer,
                    and more secure. We also provide ways to interact across the
                    WeLivv Company Products that you use, and designed systems
                    to achieve a seamless and consistent experience across the
                    WeLivv Company Products.
                </p>

                <p className="important-notice">
                    Ensuring access to our Service
                </p>
                <p>
                    To operate our global Service, we must store and transfer
                    data across our systems around the world, including outside
                    of your country of residence. The use of this global
                    infrastructure is necessary and essential to provide our
                    Service. This infrastructure may be owned or operated by
                    WeLivv Inc., or their affiliates.
                </p>

                <p className="important-notice">
                    Connecting you with brands, products, and services in ways
                    you care about
                </p>
                <p>
                    We use data from Applaudable and other WeLivv Company
                    Products, as well as from third-party partners, to show you
                    ads, offers, and other sponsored content that we believe
                    will be meaningful to you. And we try to make that content
                    as relevant as all your other experiences on Applaudable.
                </p>

                <p className="important-notice">Research and innovation</p>
                <p>
                    We use the information we have to study our Service and
                    collaborate with others on research to make our Service
                    better and contribute to the well-being of our community.
                </p>

                <p className="important-notice">How Our Service Is Funded</p>
                <p>
                    Instead of paying to use Applaudable, by using the Service
                    covered by these Terms, you acknowledge that we can show you
                    ads that businesses and organizations pay us to promote on
                    and off the WeLivv Company Products. We use your personal
                    data, such as information about your activity and interests,
                    to show you ads that are more relevant to you.
                    <br />
                    <br />
                    We show you relevant and useful ads without telling
                    advertisers who you are. We don’t sell your personal data.
                    We allow advertisers to tell us things like their business
                    goal and the kind of audience they want to see their ads. We
                    then show their ad to people who might be interested.
                    <br />
                    <br />
                    We also provide advertisers with reports about the
                    performance of their ads to help them understand how people
                    are interacting with their content on and off Applaudable.
                    For example, we provide general demographic and interest
                    information to advertisers to help them better understand
                    their audience. We don’t share information that directly
                    identifies you (information such as your name or email
                    address that by itself can be used to contact you or
                    identifies who you are) unless you give us specific
                    permission. Learn more about how Applaudable ads work here.
                    <br />
                    <br />
                    You may see branded content on Applaudable posted by account
                    holders who promote products or services based on a
                    commercial relationship with the business partner mentioned
                    in their content. You can learn more about this here.
                </p>

                <p className="important-notice">The Data Policy</p>
                <p>
                    Providing our Service requires collecting and using your
                    information. The Data Policy explains how we collect, use,
                    and share information across the WeLivv Products. It also
                    explains the many ways you can control your information,
                    including in the Applaudable Privacy and Security Settings.
                    You must agree to the Data Policy to use Applaudable.
                </p>

                <p className="important-notice">Data Retention and Deletion:</p>
                <p>
                    We retain your personal data only for as long as necessary
                    to provide you with our Service and for legitimate and
                    essential business purposes, such as maintaining the
                    performance of the Service, making data-driven business
                    decisions, complying with our legal obligations, and
                    resolving disputes. If you no longer want us to use your
                    information to provide the Service to you, you can request
                    that we delete your personal data and close your account.
                    Please note that if you request the deletion of your
                    personal data:
                    <br />
                    <ul>
                        <li>
                            We may retain some of your personal data as
                            necessary for our legitimate business interests,
                            such as fraud detection and prevention and enhancing
                            safety.
                        </li>
                        <li>
                            We may retain and use your personal data to the
                            extent necessary to comply with our legal
                            obligations. For example, Applaudable may keep
                            information for tax, legal reporting, and auditing
                            obligations.
                        </li>
                        <li>
                            Because we maintain the Service to protect from
                            accidental or malicious loss and destruction,
                            residual copies of your personal data may not be
                            removed from our backup systems for a limited
                            period.
                        </li>
                    </ul>
                </p>

                <p className="important-notice">Your Commitments</p>
                <p>
                    In return for our commitment to provide the Service, we
                    require you to make the below commitments to us.
                    <br />
                    <br />
                    Who Can Use Applaudable. We want our Service to be as open
                    and inclusive as possible, but we also want it to be safe,
                    secure, and in accordance with the law. So, we need you to
                    commit to a few restrictions in order to be part of the
                    Applaudable community.
                </p>

                <p className="important-notice">
                    You must be at least 13 years old
                </p>
                <p>
                    You must not be prohibited from receiving any aspect of our
                    Service under applicable laws or engaging in payments
                    related Services if you are on an applicable denied party
                    listing. We must not have previously disabled your account
                    for violation of law or any of our policies. You must not be
                    a convicted sex offender.
                </p>

                <p className="important-notice">Protection of Minors:</p>
                <p>
                    The Service is not directed to individuals under the age of
                    13. We do not knowingly collect personal information from
                    children under 13. If we become aware that a child under 13
                    has provided us with personal information, we will take
                    steps to delete such information from our files as soon as
                    possible.
                </p>

                <p className="important-notice">
                    How You Can't Use Applaudable. Providing a safe and open
                    Service for a broad community requires that we all do our
                    part
                </p>
                <p>
                    You can't impersonate others or provide inaccurate
                    information. You don't have to disclose your identity on
                    Applaudable, but you must provide us with accurate and up to
                    date information (including registration information), which
                    may include providing personal data. Also, you may not
                    impersonate someone or something you aren't, and you can't
                    create an account for someone else unless you have their
                    express permission.
                    <br />
                    <br />
                    You can't do anything unlawful, misleading, or fraudulent or
                    for an illegal or unauthorized purpose.
                    <br />
                    <br />
                    You can't violate (or help or encourage others to violate)
                    these Terms or our policies, including in particular the
                    Applaudable Community Guidelines, WeLivv Platform Terms and
                    Developer Policies, and Music Guidelines. If you post
                    branded content, you must comply with our Branded Content
                    Policies, which require you to use our branded content tool.
                    Learn how to report conduct or content in our Help Center.
                    <br />
                    <br />
                    You can't do anything to interfere with or impair the
                    intended operation of the Service. This includes misusing
                    any reporting, dispute, or appeals channel, such as by
                    making fraudulent or groundless reports or appeals.
                    <br />
                    <br />
                    You can't attempt to create accounts or access or collect
                    information in unauthorized ways. This includes creating
                    accounts or collecting information in an automated way
                    without our express permission.
                    <br />
                    <br />
                    You can’t sell, license, or purchase any account or data
                    obtained from us or our Service. This includes attempts to
                    buy, sell, or transfer any aspect of your account (including
                    your username); solicit, collect, or use login credentials
                    or badges of other users; or request or collect Applaudable
                    usernames, passwords, or misappropriate access tokens.
                    <br />
                    <br />
                    You can't post someone else’s private or confidential
                    information without permission or do anything that violates
                    someone else's rights, including intellectual property
                    rights (e.g., copyright infringement, trademark
                    infringement, counterfeit, or pirated goods). You may use
                    someone else's works under exceptions or limitations to
                    copyright and related rights under applicable law. You
                    represent you own or have obtained all necessary rights to
                    the content you post or share. Learn more, including how to
                    report content that you think infringes your intellectual
                    property rights, here.
                    <br />
                    <br />
                    You can’t modify, translate, create derivative works of, or
                    reverse engineer our products or their components.
                    <br />
                    <br />
                    You can't use a domain name or URL in your username without
                    our prior written consent.
                </p>

                <p className="important-notice">Permissions You Give to Us </p>
                <p>
                    As part of our agreement, you also give us permissions that
                    we need to provide the Service.
                    <br />
                    <br />
                    We do not claim ownership of your content, but you grant us
                    a license to use it. Nothing is changing about your rights
                    in your content. We do not claim ownership of your content
                    that you post on or through the Service and you are free to
                    share your content with anyone else, wherever you want.
                    However, we need certain legal permissions from you (known
                    as a “license”) to provide the Service. When you share,
                    post, or upload content that is covered by intellectual
                    property rights (like photos or videos) on or in connection
                    with our Service, you hereby grant to us a non-exclusive,
                    royalty-free, transferable, sub-licensable, worldwide
                    license to host, use, distribute, modify, run, copy,
                    publicly perform or display, translate, and create
                    derivative works of your content (consistent with your
                    privacy and application settings). This license will end
                    when your content is deleted from our systems. You can
                    delete content individually or all at once by deleting your
                    account. To learn more about how we use information, and how
                    to control or delete your content, review the Data Policy
                    and visit the Applaudable Help Center. Permission to use
                    your username, profile picture, and information about your
                    relationships and actions with accounts, ads, and sponsored
                    content. You give us permission to show your username,
                    profile picture, and information about your actions (such as
                    likes) or relationships (such as follows) next to or in
                    connection with accounts, ads, offers, and other sponsored
                    content that you follow or engage with that are displayed on
                    WeLivv Products, without any compensation to you. For
                    example, we may show that you liked a sponsored post created
                    by a brand that has paid us to display its ads on
                    Applaudable. As with actions on other content and follows of
                    other accounts, actions on sponsored content and follows of
                    sponsored accounts can be seen only by people who have
                    permission to see that content or follow. We will also
                    respect your ad settings. You can learn more here about your
                    ad settings.
                    <br />
                    <br />
                    You agree that we can download and install updates to the
                    Service on your device.
                </p>

                <p className="important-notice">Additional Rights We Retain</p>
                <p>
                    If you select a username or similar identifier for your
                    account, we may change it if we believe it is appropriate or
                    necessary (for example, if it infringes someone's
                    intellectual property or impersonates another user).
                    <br />
                    <br />
                    If you use content covered by intellectual property rights
                    that we have and make available in our Service (for example,
                    images, designs, videos, or sounds we provide that you add
                    to content you create or share), we retain all rights to our
                    content (but not yours).
                    <br />
                    <br />
                    You can only use our intellectual property and trademarks or
                    similar marks as expressly permitted by our Brand Guidelines
                    or with our prior written permission.
                    <br />
                    <br />
                    You must obtain written permission from us or under an open
                    source license to modify, create derivative works of,
                    decompile, or otherwise attempt to extract source code from
                    us.
                </p>

                <p className="important-notice">
                    Content Removal and Disabling or Terminating Your Account
                </p>
                <p>
                    We can remove any content or information you share on the
                    Service if we believe that it violates these Terms of Use,
                    our policies (including our Applaudable Community
                    Guidelines), or we are permitted or required to do so by
                    law. We can refuse to provide or stop providing all or part
                    of the Service to you (including terminating or disabling
                    your access to the WeLivv Products and WeLivv Company
                    Products) immediately to protect our community or services,
                    or if you create risk or legal exposure for us, violate
                    these Terms of Use or our policies (including our
                    Applaudable Community Guidelines), if you repeatedly
                    infringe other people's intellectual property rights, or
                    where we are permitted or required to do so by law. We can
                    also terminate or change the Service, remove or block
                    content or information shared on our Service, or stop
                    providing all or part of the Service if we determine that
                    doing so is reasonably necessary to avoid or mitigate
                    adverse legal or regulatory impacts on us. If you believe
                    your account has been terminated in error, or you want to
                    disable or permanently delete your account, consult our Help
                    Center. When you request to delete content or your account,
                    the deletion process will automatically begin no more than
                    30 days after your request. It may take up to 90 days to
                    delete content after the deletion process begins. While the
                    deletion process for such content is being undertaken, the
                    content is no longer visible to other users, but remains
                    subject to these Terms of Use and our Data Policy. After the
                    content is deleted, it may take us up to another 90 days to
                    remove it from backups and disaster recovery systems.
                </p>

                <p className="important-notice">
                    Content will not be deleted within 90 days of the account
                    deletion or content deletion process beginning in the
                    following situations:
                </p>
                <p>
                    <ul>
                        <li>
                            Where your content has been used by others in
                            accordance with this license and they have not
                            deleted it (in which case this license will continue
                            to apply until that content is deleted); or
                        </li>
                        <li>
                            Where deletion within 90 days is not possible due to
                            technical limitations of our systems, in which case,
                            we will complete the deletion as soon as technically
                            feasible; or
                        </li>
                        <li>Where deletion would restrict our ability to:</li>
                        <li>
                            Investigate or identify illegal activity or
                            violations of our terms and policies (for example,
                            to identify or investigate misuse of our products or
                            systems);
                        </li>
                        <li>
                            Protect the safety and security of our products,
                            systems, and users;
                        </li>
                        <li>
                            Comply with a legal obligation, such as the
                            preservation of evidence; or
                        </li>
                        <li>
                            Comply with a request of a judicial or
                            administrative authority, law enforcement, or a
                            government agency;
                        </li>
                        <li>
                            In which case, the content will be retained for no
                            longer than is necessary for the purposes for which
                            it has been retained (the exact duration will vary
                            on a case-by-case basis).
                        </li>
                    </ul>
                    If you delete or we disable your account, these Terms shall
                    terminate as an agreement between you and us, but this
                    section and the section below called "Our Agreement and What
                    Happens if We Disagree" will still apply even after your
                    account is terminated, disabled, or deleted.
                </p>

                <p className="important-notice">
                    Our Agreement and What Happens if We Disagree
                </p>
                <p className="important-notice">Our Agreement</p>
                <p>
                    Your use of music on the Service is also subject to our
                    Music Guidelines, and your use of our API is subject to our
                    WeLivv Platform Terms and Developer Policies. If you use
                    certain other features or related services, you will be
                    provided with an opportunity to agree to additional terms
                    that will also become a part of our agreement. For example,
                    if you use payment features, you will be asked to agree to
                    the Community Payment Terms. If any of those terms conflict
                    with this agreement, those other terms will govern.
                    <br />
                    <br />
                    If any aspect of this agreement is unenforceable, the rest
                    will remain in effect.
                    <br />
                    <br />
                    Any amendment or waiver to our agreement must be in writing
                    and signed by us. If we fail to enforce any aspect of this
                    agreement, it will not be a waiver. We reserve all rights
                    not expressly granted to you.
                </p>

                <p className="important-notice">
                    Who Has Rights Under this Agreement
                </p>
                <p>
                    Our past, present, and future affiliates and agents,
                    including WeLivv, Inc, can invoke our rights under this
                    agreement in the event they become involved in a dispute.
                    Otherwise, this agreement does not give rights to any third
                    parties.
                    <br />
                    <br />
                    You cannot transfer your rights or obligations under this
                    agreement without our consent. Our rights and obligations
                    can be assigned to others. For example, this could occur if
                    our ownership changes (as in a merger, acquisition, or sale
                    of assets) or by law.
                </p>

                <p className="important-notice">
                    Who Is Responsible if Something Happens
                </p>
                <p>
                    Our Service is provided "as is," and we can't guarantee it
                    will be safe and secure or will work perfectly all the time.
                    TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL
                    WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
                    <br />
                    <br />
                    We also don’t control what people and others do or say, and
                    we aren’t responsible for their (or your) actions or conduct
                    (whether online or offline) or content (including unlawful
                    or objectionable content). We also aren’t responsible for
                    services and features offered by other people or companies,
                    even if you access them through our Service. Our
                    responsibility for anything that happens on the Service
                    (also called "liability") is limited as much as the law will
                    allow. If there is an issue with our Service, we can't know
                    what all the possible impacts might be. You agree that we
                    won't be responsible ("liable") for any lost profits,
                    revenues, information, or data, or consequential, special,
                    indirect, exemplary, punitive, or incidental damages arising
                    out of or related to these Terms, even if we know they are
                    possible. This includes when we delete your content,
                    information, or account. Our aggregate liability arising out
                    of or relating to these Terms will not exceed the greater of
                    $100 or the amount you have paid us in the past twelve
                    months. You agree to defend (at our request), indemnify and
                    hold us harmless from and against any claims, liabilities,
                    damages, losses, and expenses, including without limitation,
                    reasonable attorney's fees and costs, arising out of or in
                    any way connected with these Terms or your use of the
                    Service. You will cooperate as required by us in the defense
                    of any claim. We reserve the right to assume the exclusive
                    defense and control of any matter subject to indemnification
                    by you, and you will not in any event settle any claim
                    without our prior written consent.
                </p>

                <p className="important-notice">How We Will Handle Disputes</p>
                <p>
                    Except as provided below, you and we agree that any cause of
                    action, legal claim, or dispute between you and us arising
                    out of or related to these Terms or Applaudable ("claim(s)")
                    must be resolved by arbitration on an individual basis.
                    Class actions and class arbitrations are not permitted; you
                    and we may bring a claim only on your own behalf and cannot
                    seek relief that would affect other Applaudable users. If
                    there is a final judicial determination that any particular
                    claim (or a request for particular relief) cannot be
                    arbitrated in accordance with this provision's limitations,
                    then only that claim (or only that request for relief) may
                    be brought in court. All other claims (or requests for
                    relief) remain subject to this provision.
                    <br />
                    <br />
                    Instead of using arbitration, you or we can bring claims in
                    your local "small claims" court, if the rules of that court
                    will allow it. If you don't bring your claims in small
                    claims court (or if you or we appeal a small claims court
                    judgment to a court of general jurisdiction), then the
                    claims must be resolved by binding, individual arbitration.
                    The American Arbitration Association will administer all
                    arbitrations under its Consumer Arbitration Rules. You and
                    we expressly waive a trial by jury.
                    <br />
                    <br />
                    The following claims don't have to be arbitrated and may be
                    brought in court: disputes related to intellectual property
                    (like copyrights and trademarks), violations of our Platform
                    Policy, or efforts to interfere with the Service or engage
                    with the Service in unauthorized ways (for example,
                    automated ways). In addition, issues relating to the scope
                    and enforceability of the arbitration provision are for a
                    court to decide.
                </p>

                <p className="important-notice">
                    This arbitration provision is governed by the Federal
                    Arbitration Act
                </p>
                <p>
                    You can opt out of this provision within 30 days of the date
                    that you agreed to these Terms. To opt out, you must send
                    your name, residence address, username, email address or
                    phone number you use for your Applaudable account, and a
                    clear statement that you want to opt out of this arbitration
                    agreement, and you must send them here: WeLivv, Inc. ATTN:
                    Applaudable Arbitration Opt-out, 417 5th Avenue, 8th Floor,
                    New York, NY 10016.
                    <br />
                    <br />
                    Before you commence arbitration of a claim, you must provide
                    us with a written Notice of Dispute that includes your name,
                    residence address, username, email address or phone number
                    you use for your Applaudable account, a detailed description
                    of the dispute, and the relief you seek. Any Notice of
                    Dispute you send to us should be mailed to WeLivv, Inc.,
                    ATTN: Applaudable Arbitration Filing, 417 5th Avenue, 8th
                    Floor, New York, NY 10016. Before we commence arbitration,
                    we will send you a Notice of Dispute to the email address
                    you use with your Applaudable account, or other appropriate
                    means. If we are unable to resolve a dispute within thirty
                    (30) days after the Notice of Dispute is received, you or we
                    may commence arbitration.
                    <br />
                    <br />
                    We will pay all arbitration filing fees, administration and
                    hearing costs, and arbitrator fees for any arbitration we
                    bring. For all other claims, the costs and fees of
                    arbitration shall be allocated in accordance with the
                    arbitration provider's rules, including rules regarding
                    frivolous or improper claims.
                    <br />
                    <br />
                    For any claim that is not arbitrated or resolved in small
                    claims court, you agree that it will be resolved exclusively
                    in the U.S. District Court for the Southern District of New
                    York or a state court located in New York. You also agree to
                    submit to the personal jurisdiction of either of these
                    courts for the purpose of litigating any such claim.
                    <br />
                    <br />
                    The laws of the State of New York, to the extent not
                    preempted by or inconsistent with federal law, will govern
                    these Terms and any claim, without regard to conflict of law
                    provisions.
                </p>

                <p className="important-notice">Unsolicited Material</p>
                <p>
                    We always appreciate feedback or other suggestions, but may
                    use them without any restrictions or obligation to
                    compensate you for them, and are under no obligation to keep
                    them confidential.
                </p>

                <p className="important-notice">
                    Objectionable Content and Abusive Users
                </p>
                <p className="important-notice">Zero Tolerance Policy</p>
                <p>
                    Applaudable enforces a zero-tolerance policy for content
                    that's harmful, offensive, or objectionable. Users who
                    violate this will have their content removed and potentially
                    have their accounts terminated.
                </p>

                <p className="important-notice">Moderation and Reporting</p>
                <p>
                    Our team proactively reviews and acts on flagged content
                    within 24 hours. Users can report objectionable material by
                    tapping the "Flag" icon next to posts or profiles.
                </p>

                <p className="important-notice">User Responsibility</p>
                <p>
                    You're solely responsible for all activity and content under
                    your account. By using Applaudable, you agree not to post or
                    propagate prohibited content and to report any you
                    encounter.
                </p>

                <p className="important-notice">Content Categories</p>
                <p>
                    Prohibited content includes but isn't limited to:
                    <ul>
                        <li>Unlawful or promoting unlawful activity</li>
                        <li>Discriminatory remarks or hate speech</li>
                        <li>Spam, unauthorized ads, or solicitation</li>
                        <li>Malware or malicious code</li>
                        <li>Violations of intellectual property</li>
                        <li>Impersonation of others</li>
                        <li>Invasion of privacy</li>
                        <li>False information or misinformation</li>
                        <li>Divisive or disruptive content</li>
                    </ul>
                </p>

                <p className="important-notice">Company Discretion</p>
                <p>
                    We reserve the right to evaluate the appropriateness of
                    content, and may remove or edit material without prior
                    notice.
                </p>

                <p className="important-notice">Risk Acknowledgment</p>
                <p>
                    You understand that using Applaudable exposes you to a range
                    of opinions and content. We're not liable for your exposure
                    to any material you find offensive or objectionable.
                </p>

                <p className="important-notice">Updating These Terms</p>
                <p>
                    We may change our Service and policies, and we may need to
                    make changes to these Terms so that they accurately reflect
                    our Service and policies. Unless otherwise required by law,
                    we will notify you (for example, through our Service) before
                    we make changes to these Terms and give you an opportunity
                    to review them before they go into effect. Then, if you
                    continue to use the Service, you will be bound by the
                    updated Terms. If you do not want to agree to these or any
                    updated Terms, you can delete your account, here.
                    <br />
                    <br />
                    Revised: April 1, 2024
                </p>
            </div>
        </div>
    );
};

export default TermsContainer;
