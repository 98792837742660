import ellipse from "../../assets/images/Ellipse 6097.png";

function Begining() {
    return (
        <div className="flex justify-center">
            <div className="rounded-lg flex flex-col justify-center items-start lg:py-18 py-10 xl:px-16 px-8 mt-8 max-w-[1150px] mx-4">
                <p className="font-extrabold xl:text-[44px] text-[32px] leading-[57px] text-left">
                    Our Beginning
                </p>
                <p className="text-base leading-8 sm:pt-20 pt-8 font-bold">
                    It started decades ago…
                </p>
                <p className="text-base leading-8 pt-8">
                    I had a rather unusual curiosity about people from a young
                    age. I remember wondering who strangers were, where they’d
                    been, what they did, etc. Over time, this curiosity matured
                    a bit. I’d wonder how people could end up in such different
                    places and with such vastness in their quality of life from
                    others with similar starting points. I became fascinated
                    with what affected this and how much we were even aware of,
                    as it was happening. How much was deliberate?
                </p>
                <p className="text-base leading-8 pt-8">
                    I learned a lot from developing the original vision of the
                    company, which focused on understanding the impact our
                    physical surroundings have on how we feel and the standard
                    to which environments inform how we live our lives. We
                    focused on curating photos of great interiors and using them
                    to inspire and inform improvements in the spaces of others.
                    More than just developing a perspective on the significance
                    of environments, often in ways not recognized consciously,
                    we also began to understand why people shared personal
                    experiences (which initially were limited to photos of their
                    homes) publicly and the impact of those experiences on
                    others. The idea that we could share something from our own
                    lives in a way that could improve somebody else’s was
                    exciting, and obviously, its potential wasn’t limited to
                    photos of people’s homes. This is how Applaudable was
                    conceived.
                </p>
                <div className="flex items-center justify-center pt-10">
                    <div className="flex-shrink-0">
                        <img
                            className="w-full h-full rounded-full"
                            src={ellipse}
                            alt="Neil image"
                        />
                    </div>
                    <div className="flex-1 min-w-0 ms-4">
                        <p className="text-[20px] leading-8 font-extrabold">
                            Andrew Christodoulides
                        </p>
                        <p className="text-[14px] text-white">
                            Founder at Applaudable
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Begining;
