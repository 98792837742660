import device1 from "../../assets/images/device1.png";

function LandingEvery() {
    return (
        <div className="lg:py-32 px-4 py-8 flex flex-col">
            <div className="lg:bg-[#213837] rounded-3xl grid grid-cols-2 gap-12 lg:relative mt-[20px] lg:py-32">
                <div className="flex justify-center lg:col-span-1 col-span-2">
                    <img
                        src={device1}
                        alt="device2"
                        className="w-[400px] h-[820px] lg:absolute block left-[100px] top-[-100px] object-contain"
                    />
                </div>
                <div className="lg:px-10 px-0 flex flex-col lg:col-span-1 col-span-2 float-right lg:mt-0 mt-8">
                    <p className="lg:text-[44px] text-[32px] font-extrabold lg:leading-[57px] leading-[40px] text-left">
                        Everyday Joys and Discoveries
                    </p>
                    <p className="mt-10 lg:leading-8 leading-[25px]">
                        These applauds capture the essence of daily life – the
                        places we visit, the activities we engage in, the media
                        we consume, and the joy of companionship. They're about
                        celebrating the present and finding beauty in the
                        ordinary.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LandingEvery;
