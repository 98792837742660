import Animation from "../../components/Animation";

function LandingExp() {
    return (
        <div className="bg-[#213837] flex justify-center items-end lg:py-36 py-24">
            <div className="grid grid-cols-10 max-w-[1500px]">
                <div className="lg:col-span-5 lg:hidden block col-span-10 flex-col justify-center items-center lg:px-20 px-4 lg:py-4 max-w-[600px]">
                    <p className="font-extrabold lg:text-[44px] text-[32px] lg:leading-[66px] leading-[40px]">
                        The Experiences Behind 7 Billion Stories
                    </p>
                    <p className="text-base lg:leading-8 leading-[25px] pt-8">
                        We believe everyone has a story – everyone has
                        experiences worth sharing. We want to capture and share
                        them all, sending ripple effects through their impact on
                        the lives of others.
                    </p>
                </div>
                <div className="lg:col-span-5 col-span-10 flex flx-col xl:mt-10 lg:mt-20 lg:ml-0 lg:px-20 sm:ml-36 ml-0 px-8 pt-[200px] pb-32 lg:py-0">
                    <Animation />
                </div>
                <div className="lg:col-span-5 col-span-10 lg:block hidden justify-center items-center lg:px-10 px-4 py-4 max-w-[600px]">
                    <p className="font-extrabold lg:text-[44px] text-[32px] lg:leading-[66px] leading-[40px]">
                        The Experiences Behind 7 Billion Stories
                    </p>
                    <p className="text-base lg:leading-8 leading-[25px] pt-8">
                        We believe everyone has a story – everyone has
                        experiences worth sharing. We want to capture and share
                        them all, sending ripple effects through their impact on
                        the lives of others.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LandingExp;
