import { Link } from "react-router-dom";
import GooglePlay from "../../assets/images/button/GooglePlay.png";
import AppStore from "../../assets/images/button/AppStore.png";
import iphone from "../../assets/images/iphone.png";
import { config } from "../../config/config";

function LandingJourney() {
    return (
        <div
            className="lg:px-4 px-4 lg:py-40 py-24 pt-0 flex flex-col gap-8"
            id="get-app-web"
        >
            <div className="lg:bg-[#213837] rounded-3xl grid grid-cols-2 lg:relative">
                <div className="lg:col-span-1 col-span-2 lg:block hidden lg:px-16 px-0 py-32 justify-center items-center">
                    <p className="font-bold lg:text-[44px] text-[36px] leading-[57px]">
                        Your journey, your experiences,
                    </p>
                    <p className="py-8">
                        Are the essence of Applaudable. Here, we come together
                        to celebrate not just the milestones but every step,
                        every story, that enriches our collective human
                        experience.
                    </p>
                    <div className="flex gap-5">
                        <button className="bg-white rounded-lg">
                            <Link to={config.SOURCE.APPLE_URL}>
                                <img
                                    src={AppStore}
                                    alt="GooglePlay"
                                    className="p-2"
                                />
                            </Link>
                        </button>
                        <button className="bg-white rounded-lg">
                            <Link to={config.SOURCE.GOOGLE_URL}>
                                <img
                                    src={GooglePlay}
                                    alt="AppStore"
                                    className="p-2"
                                />
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="lg:col-span-1 col-span-2 flex justify-center lg:pt-0">
                    <img
                        src={iphone}
                        alt="device1"
                        className="w-[400px] h-[820px] lg:absolute block right-[120px] top-[-130px] object-contain"
                    />
                </div>
                <div className="lg:col-span-1 lg:hidden col-span-2 lg:px-10 px-0 flex flex-col justify-center lg:my-auto mt-24 items-center how">
                    <p
                        className="font-bold lg:text-6xl text-[36px] leading-[66px]"
                        id="get-app-mobile"
                    >
                        Your journey, your experiences,
                    </p>
                    <p className="py-8">
                        Are the essence of Applaudable. Here, we come together
                        to celebrate not just the milestones but every step,
                        every story, that enriches our collective human
                        experience.
                    </p>
                    <div className="flex gap-5">
                        <button className="bg-white rounded-lg">
                            <Link to={config.SOURCE.APPLE_URL}>
                                <img
                                    src={AppStore}
                                    alt="GooglePlay"
                                    className="p-2"
                                />
                            </Link>
                        </button>
                        <button className="bg-white rounded-lg">
                            <Link to={config.SOURCE.GOOGLE_URL}>
                                <img
                                    src={GooglePlay}
                                    alt="AppStore"
                                    className="p-2"
                                />
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingJourney;
