import React, { useState } from "react";
import RequestInvitation from "../containers/Invitation/RequestInvitation";
import ConfirmInvitation from "../containers/Invitation/ConfirmInvitation";

const Invitation: React.FC = () => {
    window.scrollTo(0, 0);
    const [isInvitationRequested, setIsInvitationRequested] = useState(true);

    // Step 2: Event handler to update state
    const handleContinueClick = () => {
        setIsInvitationRequested(false);
    };

    return (
        <div>
            {isInvitationRequested ? (
                <RequestInvitation onContinue={handleContinueClick} />
            ) : (
                <ConfirmInvitation />
            )}
        </div>
    );
};

export default Invitation;
