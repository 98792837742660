import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import TermsPrivacy from "./pages/Terms";
import Invitation from "./pages/Invitation";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
import AboutUs from "./pages/About";
import Press from "./pages/Press";
import MobileAccess from "./pages/MobileAccess";

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/terms-privacy" element={<TermsPrivacy />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/press" element={<Press />} />
            <Route path="/by-invitation" element={<Invitation />} />
            <Route path="/invite" element={<MobileAccess />} />
            <Route path="/circle" element={<MobileAccess />} />
        </Routes>
    );
};

export default AppRouter;
