export const config = {
    API: {
        // SERVER_API: "http://localhost:8000/api/v1",
        SERVER_API: process.env.REACT_APP_SERVER_API,
    },
    SOURCE: {
        VIDEO_URL:
            "https://applaudable-main.s3.eu-west-1.amazonaws.com/videos/highlight.mp4",
        APPLE_URL: "https://apps.apple.com/us/app/applaudable/id6443599864",
        GOOGLE_URL:
            "https://play.google.com/store/apps/details?id=com.applaudable.applaudable",
    },
};
