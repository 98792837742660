import { MouseEventHandler, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import icon from "../assets/icons/icon.png";
import "../App.css";

function TopNav() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();
    let location = useLocation();
    const [navString, setNavString] = useState<string>(
        location.pathname.slice(1)
    );

    const handleNavClick = (e: React.MouseEvent<HTMLDivElement>) => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        setNavString(location.pathname.slice(1));
    }, [location.pathname]);

    // function scrollToSection(sectionId: string): MouseEventHandler {
    //     return () => {
    //         const section = document.getElementById(sectionId);
    //         if (section) {
    //             section.scrollIntoView({ behavior: "smooth" });
    //         }
    //     };
    // }

    const handleWebNavigate = () => {
        setIsNavOpen(false);
        // Navigate and then use a hash to target the specific section
        navigate("/#get-app-web");
    };

    const handleMobileNavigate = () => {
        setIsNavOpen(false);
        // Navigate and then use a hash to target the specific section
        navigate("/#get-app-mobile");
    };

    return (
        <div className="flex items-center justify-between lg:px-10 px-2 lg:py-0 py-1 sticky top-0 bg-[#152E2C] z-50 lg:border-b-2 border-[#213837]">
            <Link onClick={() => setIsNavOpen(false)} to="/">
                <img src={icon} className="h-16" alt="Applaudable vector" />
            </Link>
            <div className="flex justify-between items-center">
                {!isNavOpen && (
                    <div className="sm:col-span-3 text-right">
                        <button
                            className="rounded-full bg-[#F72D55] hover:bg-[#e68195] py-2 px-6 mr-6 lg:hidden block"
                            onClick={handleMobileNavigate}
                        >
                            <p className="text-[16px]">Get the App</p>
                        </button>
                    </div>
                )}
                <nav className="flex justify-around items-center">
                    <section className="MOBILE-MENU flex lg:hidden">
                        {!isNavOpen ? (
                            <div
                                className="space-y-1 p-4 border border-gray-400 rounded-[2rem] hover:border-white cursor-pointer"
                                onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
                            >
                                <span className="block h-[2px] w-[14px] animate-pulse bg-white"></span>
                                <span className="block h-[2px] w-[14px] animate-pulse bg-white"></span>
                                <span className="block h-[2px] w-[14px] animate-pulse bg-white"></span>
                            </div>
                        ) : (
                            <div
                                className="HAMBURGER-ICON space-y-1 p-[13px] border border-gray-400 rounded-[2rem] hover:border-white cursor-pointer"
                                onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
                            >
                                <svg
                                    className="h-5 w-5 text-white hover:text-white cursor-pointer"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </div>
                        )}
                        <div
                            className={
                                isNavOpen ? "showMenuNav" : "hideMenuNav"
                            }
                        >
                            <div className="MENU-LINK-MOBILE-OPEN w-full flex flex-col transition items-center justify-center min-h-[250px] relative px-4">
                                <Link
                                    to="/about-us"
                                    className="w-full text-start"
                                >
                                    <div
                                        className={`w-full py-4 px-2 border-b-2 border-[#FFFFFF0D] font-extrabold text-[24px] hover:bg-[#213837] hover:text-gray-300 duration-200 cursor-pointer ${
                                            navString == "about-us" &&
                                            "bg-[#213837]"
                                        }`}
                                        onClick={() =>
                                            setIsNavOpen((prev) => !prev)
                                        }
                                    >
                                        About Us
                                    </div>
                                </Link>
                                <Link to="/press" className="w-full text-start">
                                    <div
                                        className={`w-full py-4 px-2 border-b-2 border-[#FFFFFF0D] font-extrabold text-[24px] hover:bg-[#213837] hover:text-gray-300 duration-200 cursor-pointer ${
                                            navString == "press" &&
                                            "bg-[#213837]"
                                        }`}
                                        onClick={() =>
                                            setIsNavOpen((prev) => !prev)
                                        }
                                    >
                                        Press
                                    </div>
                                </Link>
                                <Link
                                    to="/careers"
                                    className="w-full text-start"
                                >
                                    <div
                                        className={`w-full py-4 px-2 border-b-2 border-[#FFFFFF0D] font-extrabold text-[24px] hover:bg-[#213837] hover:text-gray-300 duration-200 cursor-pointer ${
                                            navString == "careers" &&
                                            "bg-[#213837]"
                                        }`}
                                        onClick={() =>
                                            setIsNavOpen((prev) => !prev)
                                        }
                                    >
                                        Careers
                                    </div>
                                </Link>
                                <Link
                                    to="/contact"
                                    className="w-full text-start"
                                >
                                    <div
                                        className={`w-full py-4 px-2 border-b-2 border-[#FFFFFF0D] font-extrabold text-[24px] hover:bg-[#213837] hover:text-gray-300 duration-200 cursor-pointer ${
                                            navString == "contact" &&
                                            "bg-[#213837]"
                                        }`}
                                        onClick={() =>
                                            setIsNavOpen((prev) => !prev)
                                        }
                                    >
                                        Contact
                                    </div>
                                </Link>
                            </div>
                            <div className="flex w-full py-10 px-4">
                                <button
                                    className="rounded-full bg-[#F72D55] hover:bg-[#e68195] py-2 px-6 w-full"
                                    onClick={handleMobileNavigate}
                                >
                                    Get the App
                                </button>
                            </div>
                        </div>
                    </section>

                    <div className="DESKTOP-MENU hidden space-x-8 lg:flex">
                        <Link to="/about-us">
                            <div
                                className={`p-4 border-b-2 border-white border-opacity-0 hover:border-opacity-100 text-extrabold hover:text-gray-300 duration-200 cursor-pointer ${
                                    navString == "about-us" &&
                                    "border-white border-opacity-100"
                                }`}
                                data-value="about-us"
                                onClick={handleNavClick}
                            >
                                About Us
                            </div>
                        </Link>
                        <Link to="/press">
                            <div
                                className={`p-4 border-b-2 border-white border-opacity-0 hover:border-opacity-100 text-extrabold hover:text-gray-300 duration-200 cursor-pointer ${
                                    navString == "press" &&
                                    "border-white border-opacity-100"
                                }`}
                                data-value="press"
                                onClick={handleNavClick}
                            >
                                Press
                            </div>
                        </Link>
                        <Link to="/careers">
                            <div
                                className={`p-4 border-b-2 border-white border-opacity-0 hover:border-opacity-100 text-extrabold hover:text-gray-300 duration-200 cursor-pointer ${
                                    navString == "careers" &&
                                    "border-white border-opacity-100"
                                }`}
                                data-value="careers"
                                onClick={handleNavClick}
                            >
                                Careers
                            </div>
                        </Link>
                        <Link to="/contact">
                            <div
                                className={`p-4 border-b-2 border-white border-opacity-0 hover:border-opacity-100 text-extrabold hover:text-gray-300 duration-200 cursor-pointer ${
                                    navString == "contact" &&
                                    "border-white border-opacity-100"
                                }`}
                                data-value="contact" // Custom data attribute holding the string you want to retrieve
                                onClick={handleNavClick}
                            >
                                Contact
                            </div>
                        </Link>
                    </div>
                </nav>
            </div>

            <div className="sm:col-span-3 text-right lg:block hidden">
                <button
                    className="rounded-full bg-[#F72D55] hover:bg-[#e68195] py-2 px-6 mr-6"
                    onClick={handleWebNavigate}
                >
                    Get the App
                </button>
            </div>
        </div>
    );
}

export default TopNav;
