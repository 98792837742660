import React from "react";
import CareersContainer from "../containers/Careers";

const Careers: React.FC = () => {
    // State for form data and errors

    return <CareersContainer />;
};

export default Careers;
