import React from "react";

type CircleImageProps = {
    src: string;
    alt: string;
    additionalClasses?: string;
};

const CircleImage: React.FC<CircleImageProps> = ({
    src,
    alt,
    additionalClasses = "",
}) => (
    <div className={`flex justify-center items-center ${additionalClasses}`}>
        <img src={src} alt={alt} className="w-full h-full object-contain" />
    </div>
);

export default CircleImage;
