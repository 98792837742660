import React from "react";
import Card from "../../components/Phillar";
import { FaEye, FaBullseye, FaHeart, FaWaveSquare } from "react-icons/fa";
import exchange from "../../assets/images/exchange-funds-line.png";
import focus from "../../assets/images/focus-3-line.png";
import mental from "../../assets/images/mental-health-line.png";
import thumb from "../../assets/images/thumb-up-line.png";
import Pillar from "../../components/Phillar";
import { Scrollbar, Mousewheel, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import "swiper/css/effect-coverflow";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/grid";

const content = {
    content1:
        "The conscious understanding of all we experience and the significance of each experience relative to our interests and aspirations.",
    content2:
        "Valuing the senses our experiences awaken, the emotions they evoke, and the impact they have in shaping our lives.",
    content3: "The deliberateness of the experiences we choose.",
    content4:
        "The significance of our experiences and their ripple effects, informing and influencing the lives of others.",
};

// const Purpose: React.FC = () => {
//     return (
//         <div className="lg:px-0 px-4 lg:py-18 py-16 flex personal justify-center">
//             <div className="grid grid-cols-3 gap-4 lg:py-32 max-w-[1500px] w-full">
//                 <div className="flex flex-col col-span-3 justify-center items-center float-right lg:mt-0 mt-16">
//                     <p className="lg:text-5xl text-[32px] font-extrabold leading-[57px] pb-16">
//                         Purpose: Four Pillars
//                     </p>
//                     <div className="flex flex-col gap-5 xl:gap-8 lg:flex-row lg:justify-between">
//                         <Pillar
//                             image={exchange}
//                             title="Awareness"
//                             description="The conscious understanding of all we experience and the significance of each experience relative to our interests and aspirations."
//                         />
//                         <Pillar
//                             image={thumb}
//                             title="Appreciation"
//                             description="Valuing the senses our experiences awaken, the emotions they evoke, and the impact they have in shaping our lives."
//                         />
//                         <Pillar
//                             image={mental}
//                             title="Intention"
//                             description="The deliberateness of the experiences we choose."
//                         />
//                         <Pillar
//                             image={focus}
//                             title="Impact"
//                             description="The significance of our experiences and their ripple effects, informing and influencing the lives of others."
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

const Purpose: React.FC = () => {
    return (
        <div className="py-12 flex items-center justify-center bg-[#213837]">
            <div className="grid lg:grid-cols-2 gap-4 px-4">
                <Card
                    icon={<FaEye />}
                    title="Awareness"
                    content={content.content1}
                />
                <Card
                    icon={<FaHeart />}
                    title="Appreciation"
                    content={content.content2}
                />
                <Card
                    icon={<FaBullseye />}
                    title="Intention"
                    content={content.content3}
                />
                <Card
                    icon={<FaWaveSquare />}
                    title="Impact"
                    content={content.content4}
                />
            </div>
        </div>
    );
};

export default Purpose;
