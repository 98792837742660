import React from "react";

type CircleTextSectionProps = {
    heading: string;
    description: string;
    additionalClasses?: string;
};

const CircleTextSection: React.FC<CircleTextSectionProps> = ({
    heading,
    description,
    additionalClasses = "",
}) => (
    <div
        className={`items-left my-auto lg:px-0 px-4 lg:py-0 py-16 ${additionalClasses}`}
    >
        <p className="lg:text-[44px] text-[36px] font-bold leading-[57px]">
            {heading}
        </p>
        <p className="my-10 lg:leading-8 leading-[25px]">{description}</p>
    </div>
);

export default CircleTextSection;
