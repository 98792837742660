import React, { useEffect, useState } from "react";
import MobileAccess from "../containers/Invitation/MobileAccess";

function MobileAccessPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MobileAccess />
        </>
    );
}

export default MobileAccess;
