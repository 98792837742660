import device2 from "../../assets/images/device2.png";

function LandingPersonal() {
    return (
        <div className="lg:py-32 px-4 py-8 flex flex-col">
            <div className="lg:bg-[#213837] rounded-3xl grid grid-cols-2 gap-12 lg:relative mt-[20px] lg:py-32">
                <div className="flex justify-center lg:col-span-1 col-span-2">
                    <img
                        src={device2}
                        alt="device2"
                        className="w-[400px] h-[820px] lg:absolute block left-[100px] top-[-100px] object-contain"
                    />
                </div>
                <div className="lg:px-10 px-0 flex flex-col lg:col-span-1 col-span-2 float-right lg:mt-0 mt-8">
                    <p className="lg:text-[44px] text-[32px] font-extrabold lg:leading-[57px] leading-[40px] text-left">
                        Personal Milestones and Inspirations
                    </p>
                    <p className="mt-10 lg:leading-8 leading-[25px]">
                        At a deeper level, applauds in this category are
                        reflections on personal growth and achievements. They
                        encompass aspirations, inspirations, perseverance
                        through challenges, and the significant impact of our
                        environment and relationships. These applauds are about
                        acknowledging the journey and the individuals who fuel
                        our progress.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LandingPersonal;
