import gsap from "gsap-trial";
import { useGSAP } from "@gsap/react";
import Footer from "./layout/Footer";
import TopNav from "./layout/TopNav";
import AppRouter from "./Router";
import { ParallaxProvider } from "react-scroll-parallax";
import "./App.css";

function App() {
    return (
        <ParallaxProvider>
            <div className="app">
                <TopNav />
                <AppRouter />
                <Footer />
            </div>
        </ParallaxProvider>
    );
}

export default App;
